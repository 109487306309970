import type { ComponentProps } from "react";
import { forwardRef } from "react";
import { ChevronRightIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import cn from "@/helpers/cn";
import { useCarousel } from "@/hooks/common/useCarousel";

const CarouselNext = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ className, variant = "secondary", size = "icon", ...props }, ref) => {
  const { orientation, scrollNext, canScrollNext } = useCarousel();

  return (
    <Button
      ref={ref}
      variant={variant}
      size={size}
      className={cn(
        "h-9 w-9 rounded-full",
        orientation === "horizontal"
          ? ""
          : "absolute -bottom-12 left-1/2 -translate-x-1/2 rotate-90",
        className,
      )}
      disabled={!canScrollNext}
      onClick={scrollNext}
      {...props}
    >
      <ChevronRightIcon className="h-5 w-5" />
      <span className="sr-only">Next slide</span>
    </Button>
  );
});

CarouselNext.displayName = "CarouselNext";

export default CarouselNext;
