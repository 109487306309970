import { useCallback, useEffect, useRef, useState } from "react";

import { Paragraph } from "@/components/atoms/Paragraph";
import { SINGLE_PAGE_SECTIONS } from "@/components/molecules/NavigationBarBySection/constants";
import cn from "@/helpers/cn";
import { useTranslations } from "@/hooks/common/useTranslations";

const NavigationBarBySection = () => {
  const { t } = useTranslations("SingleAppPage");
  const [activeNavTab, setActiveNavTab] = useState(SINGLE_PAGE_SECTIONS.about);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const ticking = useRef(false);
  const lastScrollTop = useRef(0);

  const itemsNavbarTab = [
    {
      key: SINGLE_PAGE_SECTIONS.about,
      label: t("about"),
    },
    {
      key: SINGLE_PAGE_SECTIONS.benefits,
      label: t("benefits"),
    },
    {
      key: SINGLE_PAGE_SECTIONS.details,
      label: t("details"),
    },
    {
      key: SINGLE_PAGE_SECTIONS.ratings,
      label: t("ratings"),
    },
  ];

  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop.current) {
          // Scrolling down
          setIsNavVisible(false);
        } else {
          setIsNavVisible(true);
          // Scrolling up
        }

        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elem = entry.target;
            setActiveNavTab(elem.id as SINGLE_PAGE_SECTIONS);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      },
    );

    Object.keys(SINGLE_PAGE_SECTIONS).forEach((key) => {
      const element = document.getElementById(key);

      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      Object.keys(SINGLE_PAGE_SECTIONS).forEach((key) => {
        const element = document.getElementById(key);

        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const onClickNavigationBarItem = (key: SINGLE_PAGE_SECTIONS) => {
    const section = document.getElementById(key);
    window.scrollTo({
      top: section?.offsetTop,
      behavior: "smooth",
    });
    setActiveNavTab(key);
    // fast solution
    setTimeout(() => {
      setIsNavVisible(true);
    }, 1000);
  };

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 opacity-0  max-sm:flex justify-center w-full border-t bg-background",
        isNavVisible && "max-sm:opacity-100",
      )}
    >
      <ul className="p-3 flex items-center gap-2">
        {itemsNavbarTab.map(({ key, label }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={key}
            onClick={() => onClickNavigationBarItem(key)}
            className={cn(
              "px-4 py-2  rounded-xl",
              activeNavTab === key && "bg-primary-light text-primary-selected",
            )}
          >
            <Paragraph fontWeight="regular" size="base">
              {label}
            </Paragraph>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationBarBySection;
