import Image from "next/image";

import type { INextImage } from "@/components/atoms/NextImage/types";

const NextImage = ({
  width,
  height,
  blurDataURL,
  src,
  alt,
  priority = false,
  sizes,
  ...props
}: INextImage) => {
  return (
    <Image
      src={src}
      width={width}
      height={height}
      blurDataURL={blurDataURL}
      placeholder={blurDataURL ? "blur" : undefined}
      quality={100}
      style={{ objectFit: "contain" }}
      alt={alt}
      priority={priority}
      sizes={sizes}
      {...props}
    />
  );
};

NextImage.displayName = "NextImage";

export default NextImage;
