import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import cn from "@/helpers/cn";

const CardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn("p-4 pt-0", className)} {...props} />
  ),
);

CardContent.displayName = "CardContent";

export default CardContent;
