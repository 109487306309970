import { Card, CardContent, CardHeader } from "@/components/atoms/Card";
import { Skeleton } from "@/components/atoms/Skeleton";

const SingleApplicationCardInfoSkeleton = () => {
  return (
    <section id="applicationInfo">
      <Card className="max-sm:border-none">
        <CardHeader className="flex flex-row justify-between p-5 pb-0 max-lg:flex-col max-lg:gap-6 max-sm:p-4">
          <div className="flex basis-4/12 gap-5">
            <Skeleton className="w-16 h-16 rounded-xl border" />
            <div className="flex flex-col gap-2">
              <Skeleton className="w-36 h-4 rounded-xl border" />
              <div className="flex items-center">
                <Skeleton className="w-32 h-4 rounded-xl border" />
              </div>
            </div>
          </div>
          <div className="flex flex-col basis-8/12 gap-2">
            <Skeleton className="w-full h-4 rounded-xl" />
            <Skeleton className="w-full h-4 rounded-xl" />
            <Skeleton className="w-3/4 h-4 rounded-xl" />
          </div>
        </CardHeader>
        <CardContent className="flex p-5 pt-6 max-lg:flex-col max-sm:p-4">
          <div className="flex flex-col gap-6 basis-4/12">
            <div className="flex flex-col gap-2">
              <Skeleton className="w-20 h-4 rounded-xl border" />
              <Skeleton className="w-32 h-4 rounded-xl border" />
            </div>
            <div className="flex items-center gap-3 max-sm:flex-col">
              <Skeleton className="w-24 h-8 max-sm:w-full rounded-xl border" />
            </div>
          </div>
          <div className="basis-8/12 border-t mt-8 max-sm:border-t-0 max-sm:mt-0">
            <div className="flex gap-4 mt-5 max-sm:flex-col">
              <div className="flex flex-col gap-1 grow border-r last:border-r-0 max-sm:flex-row max-sm:justify-between max-sm:border-r-0 max-sm:border-b max-sm:pb-4">
                <Skeleton className="w-24 h-4 rounded-xl border" />
                <Skeleton className="w-32 h-6 rounded-xl border" />
              </div>
              <div className="flex flex-col gap-1 grow border-r last:border-r-0 max-sm:flex-row max-sm:justify-between max-sm:border-r-0 max-sm:border-b max-sm:pb-4">
                <Skeleton className="w-24 h-4 rounded-xl border" />
                <Skeleton className="w-32 h-6 rounded-xl border" />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </section>
  );
};

export default SingleApplicationCardInfoSkeleton;
