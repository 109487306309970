"use client";

import { Fragment } from "react";

import type { IComponentRepeaterProps } from "./type";

const ComponentRepeater = ({ count, component }: IComponentRepeaterProps) => {
  const repeatedComponents = new Array(count)
    .fill("")
    .map((_, index) => <Fragment key={index}>{component}</Fragment>);

  return <Fragment key="componentRepeater">{repeatedComponents}</Fragment>;
};

export default ComponentRepeater;
