import { PhotoView } from "react-photo-view";
import Image from "next/image";
import { EyeOpenIcon } from "@radix-ui/react-icons";

import type { IBySlideItemTypesFunctionProps } from "@/components/organisms/CarouselApplicationImages/types";

import { GALLERY_TYPE } from "@/common/globalTypes/enums/common";

export const ComponentsBySlideItemTypes = {
  [GALLERY_TYPE.image]: ({
    src,
    alt,
    previewTxt,
  }: IBySlideItemTypesFunctionProps) => (
    <PhotoView src={src}>
      <div>
        <Image
          src={src}
          alt={alt}
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          className="object-cover"
          priority={true}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
          <span className="flex items-center justify-center gap-1 text-white text-lg ">
            <EyeOpenIcon className="h-4 w-4" />
            {previewTxt}
          </span>
        </div>
      </div>
    </PhotoView>
  ),
  [GALLERY_TYPE.video]: () => <div />,
};
