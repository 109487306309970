import ComponentRepeater from "@/components/atoms/ComponentRepeater/componentRepeater";
import { Skeleton } from "@/components/atoms/Skeleton";

const AppDetailsSkeleton = () => {
  return (
    <section className="flex flex-col gap-4 max-sm:p-4">
      <div className="flex justify-between">
        <Skeleton className="w-48 h-6 rounded-xl border" />
      </div>
      <div className="flex flex-col gap-2">
        <ComponentRepeater
          count={3}
          component={<Skeleton className="w-full h-12 rounded-xl border" />}
        />
      </div>
    </section>
  );
};

export default AppDetailsSkeleton;
