import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import cn from "@/helpers/cn";

const CardFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("flex items-center p-4 pt-0", className)}
      {...props}
    />
  ),
);

CardFooter.displayName = "CardFooter";
export default CardFooter;
