import { FileText } from "lucide-react";

import { Button } from "@/components/atoms/Button";
import { Card, CardContent, CardHeader } from "@/components/atoms/Card";
import { Heading } from "@/components/atoms/Heading";
import { Paragraph } from "@/components/atoms/Paragraph";
import { Picture } from "@/components/atoms/Picture";
import { Rating } from "@/components/atoms/Rating";
import { SINGLE_PAGE_SECTIONS } from "@/components/molecules/NavigationBarBySection";
import AppMoreDetailedInfo from "@/components/molecules/SingleApplicationCardInfo/appMoreDetailedInfo";
import type { ISingleApplicationCardProps } from "@/components/molecules/SingleApplicationCardInfo/types";
import cn from "@/helpers/cn";
import priceCounterWithTrialDays from "@/helpers/priceCounterWithTrialDays";
import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useTranslations } from "@/hooks/common/useTranslations";

const SingleApplicationCardInfo = ({
  app,
  getAppActionHandler,
}: ISingleApplicationCardProps) => {
  const { t } = useTranslations("SingleAppPage");
  const { workspaceConfig } = useAuthUser();
  const {
    name,
    logoUrl,
    categories,
    averageRating,
    description,
    reviewsCount,
    version,
    price,
    monthlyPrice,
    trialDays,
    formattedPrice,
    formattedMonthlyPrice,
    documentationUrl,
    isInstalled,
  } = app;

  const { freeApp, haveTrialDays, monthlyOrNot } = priceCounterWithTrialDays(
    price,
    monthlyPrice,
    trialDays,
  );

  const transKeyMonthlyOrYearlyApp = monthlyOrNot
    ? "applicationPriceWithoutMonthly"
    : "applicationWithMonthlyPrice";

  const applicationPrice = monthlyOrNot
    ? formattedPrice
    : formattedMonthlyPrice;

  const [category] = categories;
  const categoryName = category?.name || "—";
  const rating = averageRating > 0 ? +(5 / +averageRating).toFixed(2) : 0;

  const details = [
    { label: t("category"), value: categoryName },
    { label: t("version"), value: version },
  ];

  return (
    <section id={SINGLE_PAGE_SECTIONS.about}>
      <Card className="max-sm:border-none">
        <CardHeader className="flex flex-row justify-between p-5 pb-0 max-lg:flex-col max-lg:gap-6 max-sm:p-4">
          <div className="flex basis-4/12 gap-5">
            <Picture src={logoUrl} alt={name} width={64} height={64} />
            <div className="flex flex-col gap-2">
              <Heading size="h1" fontWeight="bold" className="text-2xl">
                {name}
              </Heading>
              <div className="flex items-center">
                <Paragraph size="small" fontWeight="regular" type="secondary">
                  {t("byCompany", {
                    clientName: workspaceConfig?.clientName || "",
                  })}
                </Paragraph>

                <div className="h-4 px-2">
                  <hr className="bg-border w-[1px] h-full" />
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex flex-nowrap items-center gap-1">
                    <Paragraph size="small" fontWeight="regular">
                      {averageRating}
                    </Paragraph>
                    <Rating
                      readOnly
                      style={{ maxWidth: 16 }}
                      items={1}
                      value={rating}
                    />
                  </div>
                  <Paragraph size="small" fontWeight="regular" type="secondary">
                    {t("reviewsCount", {
                      count: reviewsCount,
                    })}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
          <div className="basis-8/12">
            <Paragraph size="small" fontWeight="regular">
              {description}
            </Paragraph>
          </div>
        </CardHeader>
        <CardContent
          className={cn(
            "flex p-5 pt-6 max-lg:flex-col max-sm:p-4",
            freeApp && "pt-0 max-sm:pt-0",
          )}
        >
          <div className="flex flex-col gap-6 basis-4/12">
            {freeApp ? (
              <div className={cn(freeApp && "h-9 max-lg:h-0")} />
            ) : (
              <div>
                <Paragraph size="base" fontWeight="medium">
                  {t(transKeyMonthlyOrYearlyApp, {
                    price: applicationPrice,
                  })}
                </Paragraph>
                {haveTrialDays && (
                  <Paragraph size="base" fontWeight="medium" type="secondary">
                    {t("freeTrialDay", {
                      days: trialDays,
                    })}
                  </Paragraph>
                )}
              </div>
            )}

            <div className="flex items-center gap-3 max-sm:flex-col">
              <Button
                className="rounded-xl max-sm:w-full"
                onClick={() => getAppActionHandler(isInstalled)}
              >
                <Paragraph size="small" fontWeight="medium">
                  {t(isInstalled ? "manageApp" : "getApp")}
                </Paragraph>
              </Button>
              {documentationUrl && workspaceConfig?.isCore && (
                <Button
                  variant="link"
                  className="text-primary-selected max-sm:hidden"
                >
                  <FileText />
                  <a href={documentationUrl} target="_blank" rel="noreferrer">
                    <Paragraph size="small" fontWeight="medium">
                      {t("setupGuide")}
                    </Paragraph>
                  </a>
                </Button>
              )}
            </div>
          </div>
          <div className="basis-8/12 border-t mt-8 max-sm:border-t-0 max-sm:mt-0">
            <div className="flex gap-4 mt-5 max-sm:flex-col">
              <AppMoreDetailedInfo
                details={details}
                documentationUrl={documentationUrl}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </section>
  );
};

export default SingleApplicationCardInfo;
