import { FileText } from "lucide-react";

import { Button } from "@/components/atoms/Button";
import { Paragraph } from "@/components/atoms/Paragraph";
import type { ISingleApplicationCardInfoProps } from "@/components/molecules/SingleApplicationCardInfo/types";
import { useTranslations } from "@/hooks/common/useTranslations";

const AppMoreDetailedInfo = ({
  details,
  documentationUrl,
}: ISingleApplicationCardInfoProps) => {
  const { t } = useTranslations("SingleAppPage");

  return (
    <>
      {documentationUrl && (
        <div className="hidden max-sm:flex items-center flex-col gap-1 grow border-r max-sm:flex-row max-sm:justify-between max-sm:border-r-0 max-sm:border-b max-sm:pb-4">
          <Paragraph
            size="extraSmall"
            fontWeight="regular"
            type="secondary"
            className="max-sm:w-full"
          >
            {t("needAssistance")}
          </Paragraph>
          <div className="max-sm:w-full">
            <Button variant="link" className="text-primary-selected p-0">
              <FileText />
              <a href={documentationUrl} target="_blank" rel="noreferrer">
                <Paragraph size="small" fontWeight="medium">
                  {t("setupGuide")}
                </Paragraph>
              </a>
            </Button>
          </div>
        </div>
      )}

      {details.map(({ label, value }, index) => (
        <div
          key={index}
          className="flex flex-col gap-1 grow border-r last:border-r-0 max-sm:flex-row max-sm:justify-between max-sm:border-r-0 max-sm:border-b max-sm:pb-4"
        >
          <Paragraph
            size="extraSmall"
            fontWeight="regular"
            type="secondary"
            className="max-sm:w-full"
          >
            {label}
          </Paragraph>
          <Paragraph size="base" fontWeight="medium" className="max-sm:w-full">
            {value}
          </Paragraph>
        </div>
      ))}
    </>
  );
};

export default AppMoreDetailedInfo;
