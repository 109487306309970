import { Skeleton } from "@/components/atoms/Skeleton";

const CarouselApplicationImagesSkeleton = () => {
  return (
    <section className="flex flex-col gap-4 max-sm:p-4">
      <div className="flex justify-between">
        <Skeleton className="w-48 h-6 rounded-xl border" />
        <div className="flex gap-2">
          <Skeleton className="w-9 h-9 rounded-full border" />
          <Skeleton className="w-9 h-9 rounded-full border" />
        </div>
      </div>
      <div className="grid gap-4 grid-cols-2 max-md:grid-cols-1">
        <Skeleton className="w-full h-[357px] max-sm:h-[210px] rounded-2xl border" />
        <Skeleton className="w-full h-[357px] max-sm:hidden rounded-2xl border" />
      </div>
      <div className="flex justify-center items-center">
        <div className="flex gap-2">
          <Skeleton className="w-5 h-5 rounded-full border" />
          <Skeleton className="w-5 h-5 rounded-full border" />
          <Skeleton className="w-5 h-5 rounded-full border" />
        </div>
      </div>
    </section>
  );
};

export default CarouselApplicationImagesSkeleton;
