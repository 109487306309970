import type { ComponentProps } from "react";
import { forwardRef } from "react";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import cn from "@/helpers/cn";
import { useCarousel } from "@/hooks/common/useCarousel";

const CarouselPrevious = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ className, variant = "secondary", size = "icon", ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarousel();

  return (
    <Button
      ref={ref}
      variant={variant}
      size={size}
      className={cn(
        "h-9 w-9 rounded-full",
        orientation === "horizontal"
          ? ""
          : "absolute -top-12 left-1/2 -translate-x-1/2 rotate-90",
        className,
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      <ChevronLeftIcon className="h-5 w-5" />
      <span className="sr-only">Previous slide</span>
    </Button>
  );
});

CarouselPrevious.displayName = "CarouselPrevious";

export default CarouselPrevious;
