import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { Button } from "@/components/atoms/Button";
import cn from "@/helpers/cn";
import { useCarousel } from "@/hooks/common/useCarousel";
import { useDotButtonCarousel } from "@/hooks/common/useDotButtonCarousel";

const CarouselContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation, api } = useCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButtonCarousel(api);

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "horizontal" ? "" : "-mt-4 flex-col",
          className,
        )}
        {...props}
      />
      <div className="flex justify-center items-center pt-6">
        <div className="flex gap-4">
          {scrollSnaps.map((_, index) => (
            <Button
              key={index}
              className={cn(
                "h-0 rounded-full px-1 py-1",
                index === selectedIndex &&
                  "bg-primary-selected hover:bg-primary-selected",
              )}
              onClick={() => onDotButtonClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

CarouselContent.displayName = "CarouselContent";

export default CarouselContent;
