import ComponentRepeater from "@/components/atoms/ComponentRepeater/componentRepeater";
import { Skeleton } from "@/components/atoms/Skeleton";

const BenefitsSkeleton = () => {
  return (
    <section className="flex flex-col gap-4 max-sm:p-4">
      <div className="flex justify-between">
        <Skeleton className="w-48 h-6 rounded-xl border" />
      </div>
      <div className="grid gap-4 grid-cols-3 max-md:grid-cols-1">
        <ComponentRepeater
          count={3}
          component={
            <div className="flex flex-col gap-5 h-80 p-5 border rounded-2xl">
              <Skeleton className="w-32 h-6 rounded-xl border" />
              <div className="flex flex-col gap-2">
                <Skeleton className="w-full h-6 rounded-xl border" />
                <Skeleton className="w-full h-6 rounded-xl border" />
                <Skeleton className="w-full h-6 rounded-xl border" />
                <Skeleton className="w-full h-6 rounded-xl border" />
                <Skeleton className="w-full h-6 rounded-xl border" />
                <Skeleton className="w-3/4 h-6 rounded-xl border" />
              </div>
            </div>
          }
        />
      </div>
    </section>
  );
};

export default BenefitsSkeleton;
