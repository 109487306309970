import * as React from "react";
import { forwardRef } from "react";

import cn from "@/helpers/cn";

const CardHeader = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex flex-col p-4", className)} {...props} />
));

CardHeader.displayName = "CardHeader";

export default CardHeader;
