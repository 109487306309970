"use client";

import { PhotoProvider } from "react-photo-view";

import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/Carousel";
import { TitleSinglePageApp } from "@/components/molecules/TitleSingleAppPage";
import { ComponentsBySlideItemTypes } from "@/components/organisms/CarouselApplicationImages/constants";
import type { ICarouselApplicationImagesProps } from "@/components/organisms/CarouselApplicationImages/types";
import { useTranslations } from "@/hooks/common/useTranslations";

import "react-photo-view/dist/react-photo-view.css";

import { CarouselProvider } from "@/providers/carouselProvider";

const CarouselApplicationImages = ({
  galleryItems,
}: ICarouselApplicationImagesProps) => {
  const { t } = useTranslations("SingleAppPage");

  if (!galleryItems.length) {
    return null;
  }

  return (
    <section className="max-sm:pl-4">
      <CarouselProvider
        opts={{
          align: "start",
        }}
      >
        <TitleSinglePageApp
          title="Preview"
          extra={
            <div className="flex items-center gap-2">
              <CarouselPrevious />
              <CarouselNext />
            </div>
          }
        />
        <CarouselContent className="flex gap-4 h-[357px] max-sm:h-[210px]">
          <PhotoProvider>
            {galleryItems.map(({ id, alt, url, type }) => (
              <CarouselItem
                key={id}
                className="basis-[49%] max-md:basis-[95%] border rounded-2xl cursor-pointer"
              >
                <div className="relative w-full h-[357px] max-sm:h-[210px] overflow-hidden rounded-2xl group">
                  {ComponentsBySlideItemTypes[type]({
                    src: url,
                    alt,
                    previewTxt: t("preview"),
                  })}
                </div>
              </CarouselItem>
            ))}
          </PhotoProvider>
        </CarouselContent>
      </CarouselProvider>
    </section>
  );
};

export default CarouselApplicationImages;
