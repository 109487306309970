"use client";

import { useState } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/atoms/Accordion";
import { Paragraph } from "@/components/atoms/Paragraph";
import { SINGLE_PAGE_SECTIONS } from "@/components/molecules/NavigationBarBySection";
import { TitleSinglePageApp } from "@/components/molecules/TitleSingleAppPage";
import type { IAppDetailsProps } from "@/components/organisms/AppDetails/types";
import { useTranslations } from "@/hooks/common/useTranslations";

const AppDetails = ({ appDetails }: IAppDetailsProps) => {
  const { t } = useTranslations("SingleAppPage");
  const [activeTab, setActiveTab] = useState("0");

  if (!appDetails.length) {
    return null;
  }

  return (
    <section id={SINGLE_PAGE_SECTIONS.details} className="max-sm:p-4">
      <TitleSinglePageApp title={t("details")} />
      <Accordion type="single" collapsible className="w-full" value={activeTab}>
        {appDetails.map(({ id, title, description }, index) => (
          <AccordionItem
            value={String(index)}
            onClick={() => setActiveTab(String(index))}
            key={id}
          >
            <AccordionTrigger>
              <Paragraph
                size="large"
                fontWeight="medium"
                className="text-[18px]"
              >
                {title}
              </Paragraph>
            </AccordionTrigger>
            <AccordionContent>
              <Paragraph size="small" fontWeight="regular" type="secondary">
                {description}
              </Paragraph>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default AppDetails;
