import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import cn from "@/helpers/cn";

const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "rounded-xl border bg-card text-card-foreground",
        className,
      )}
      {...props}
    />
  ),
);

Card.displayName = "Card";

export default Card;
