import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import cn from "@/helpers/cn";
import { useCarousel } from "@/hooks/common/useCarousel";

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel();

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={cn(
          "min-w-0 shrink-0 grow-0 basis-full",
          orientation === "horizontal" ? "pl-0" : "pt-4",
          className,
        )}
        {...props}
      />
    );
  },
);

CarouselItem.displayName = "CarouselItem";

export default CarouselItem;
