import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import cn from "@/helpers/cn";

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("bg-secondary mb-2 rounded-2xl p-5 last:mb-0", className)}
    {...props}
  />
));

AccordionItem.displayName = "AccordionItem";

export default AccordionItem;
