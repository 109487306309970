import { NextImage } from "@/components/atoms/NextImage";
import type { IPictureProps } from "@/components/atoms/Picture/types";

const Picture = ({ src, width, height, ...props }: IPictureProps) => {
  return (
    <div style={{ width, height }} className="flex rounded-xl border bg-white">
      <NextImage
        width={width}
        height={height}
        src={src}
        className="scale-90 rounded-xl"
        {...props}
      />
    </div>
  );
};

Picture.displayName = "Picture";

export default Picture;
