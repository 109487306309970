import { Heading } from "@/components/atoms/Heading";
import { Paragraph } from "@/components/atoms/Paragraph";
import { SINGLE_PAGE_SECTIONS } from "@/components/molecules/NavigationBarBySection";
import { TitleSinglePageApp } from "@/components/molecules/TitleSingleAppPage";
import type { IBenefitsProps } from "@/components/organisms/Benefits/types";
import { useTranslations } from "@/hooks/common/useTranslations";

const Benefits = ({ appBenefits, appName }: IBenefitsProps) => {
  const { t } = useTranslations("SingleAppPage");

  if (!appBenefits.length) {
    return null;
  }

  return (
    <section id={SINGLE_PAGE_SECTIONS.benefits} className="max-sm:p-4">
      <TitleSinglePageApp
        title={t("benefitsOf", { appName })}
        isBenefitsSection
      />
      <div className="grid gap-4 grid-cols-3 max-md:grid-cols-1">
        {appBenefits.map(({ id, title, description }) => (
          <div
            className="flex flex-col gap-3 p-5 bg-secondary rounded-2xl"
            key={id}
          >
            <Heading size="h3" className="text-[18px]" fontWeight="regular">
              {title}
            </Heading>
            <Paragraph type="secondary" fontWeight="regular" size="small">
              {description}
            </Paragraph>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;
