import { Heading } from "@/components/atoms/Heading";
import type { ITitleSingleAppPageProps } from "@/components/molecules/TitleSingleAppPage/types";
import cn from "@/helpers/cn";

const TitleSingleAppPage = ({
  title,
  isBenefitsSection = false,
  extra,
}: ITitleSingleAppPageProps) => {
  return (
    <div className="flex justify-between items-center pb-6 max-sm:mr-4">
      <Heading
        size={isBenefitsSection ? "h2" : "h4"}
        fontWeight="bold"
        className={cn(isBenefitsSection && "text-2xl")}
      >
        {title}
      </Heading>

      {extra && extra}
    </div>
  );
};

export default TitleSingleAppPage;
